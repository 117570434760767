  <template>
  <div>
    <div class="mini-spacer converter pa-0">
      <v-container>
        <!-- -----------------------------------------------
            Start Contact Form
        ----------------------------------------------- -->
        <v-row justify="center" v-if="isLoadingConfig || !configSet">
          <v-col cols="12" sm="12" justify="center" align="center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
              style="text-align: center"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row justify="center" v-else>
          <v-col cols="12" sm="12" class="pa-0">
            <div>
              <v-form
                id=""
                v-model="validAmount"
                lazy-validation
                ref="amountForm"
                class="px-3 form-weight"
                @submit.prevent="emitSend"
              >
                <v-row class="elevation-5 rounded-l">
                  <v-col
                    cols="9"
                    sm="9"
                    class="pt-0 bg-container rounded-l"
                    @click="focus('fromAmount')"
                  >
                    <v-text-field
                      ref="fromAmount"
                      label="You Send"
                      v-model="sendMoneyParams.fromAmount"
                      placeholder="0.00"
                      v-validate="'required'"
                      :rules="amountRules"
                      :error-messages="errors.collect('fromAmount')"
                      @keyup="calculate('fromAmount')"
                      persistent-placeholder
                      hint="Enter amount to send"
                      :persistent-hint="true"
                      clearable
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" class="pa-0 rounded-r">
                    <v-btn
                      tile
                      class="primary-button-color input-append rounded-r"
                    >
                      {{ sendMoneyParams.fromCurrencyCode }}
                      <!-- <v-icon right dark size="30"> mdi-chevron-down </v-icon> -->
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pr-0">
                    <v-timeline align-top dense class="timeline-item">
                      <v-timeline-item small class="">
                        <template v-slot:icon>
                          <span class="white--text label-icon">+</span>
                        </template>
                        <v-row class="pa-0 currency-value">
                          <v-col cols="3" class="pa-0">
                            <strong v-if="transferType">
                              <span v-if="transferType.amount">{{
                                transferType.amount
                                  | currency(sendMoneyParams.fromCurrencyCode)
                              }}</span>
                              <span v-else> Free </span>
                              <span style="font-size: 12px">
                                <br />Payment</span
                              >
                            </strong>
                          </v-col>
                          <v-col class="pa-0">
                            <div class="mt-minus">
                              <v-menu>
                                <template
                                  v-slot:activator="{ on: menu, attrs }"
                                >
                                  <v-tooltip bottom>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        v-if="transferType"
                                        tile
                                        small
                                        class="primary-button-color"
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...menu }"
                                      >
                                        {{ transferType.title }}
                                        <v-icon right dark>
                                          mdi-chevron-down
                                        </v-icon>
                                      </v-btn>
                                      <!-- <span class="text-caption ml-1">Fee</span> -->
                                    </template>
                                    <span>Click to change transfer method</span>
                                  </v-tooltip>
                                </template>
                                <v-card class="mx-auto" max-width="500" tile>
                                  <template v-for="item in transferTypes">
                                    <v-list-item
                                      two-line
                                      :key="item.code"
                                      class="pointer"
                                      @click="setTransferType(item)"
                                    >
                                      <v-list-item-content class="pointer">
                                        <v-list-item-title
                                          >{{ item.title }} -
                                          {{ item.currencyCode }}
                                          {{ item.amount }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                          item.description
                                        }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-card>
                              </v-menu>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                      <v-timeline-item small class="">
                        <template v-slot:icon>
                          <span class="white--text label-icon">x</span>
                        </template>
                        <v-row class="pa-0 currency-value">
                          <v-col cols="3" class="pa-0">
                            <strong>{{
                              sendMoneyParams.exchangeRate | currency("")
                            }}</strong>
                          </v-col>
                          <v-col class="pa-0">
                            <div class="text-caption">Exchange Rate</div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item small class="">
                        <template v-slot:icon>
                          <span class="white--text label-icon">=</span>
                        </template>
                        <v-row class="pa-0 currency-value">
                          <v-col cols="3" class="pa-0">
                            <strong>{{
                              sendMoneyParams.amountToConvert
                            }}</strong>
                          </v-col>
                          <v-col class="pa-0">
                            <div class="text-caption">Amount we'll convert</div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item small class="">
                        <template v-slot:icon class="text-center">
                          <span class="white--text text-center label-icon"
                            >></span
                          >
                        </template>
                        <v-row class="pa-0">
                          <v-col cols="3" class="pa-0 currency-value">
                            <strong v-if="deliveryType">
                              <span v-if="deliveryType.amount">{{
                                deliveryType.amount
                                  | currency(sendMoneyParams.fromCurrencyCode)
                              }}</span>
                              <span v-else> Free </span>
                              <span style="font-size: 12px">
                                <br />Service
                              </span>
                            </strong>
                          </v-col>
                          <v-col class="pa-0">
                            <div class="mt-minus">
                              <v-menu>
                                <template
                                  v-slot:activator="{ on: menu, attrs }"
                                >
                                  <v-tooltip bottom>
                                    <template
                                      v-slot:activator="{ on: tooltip }"
                                    >
                                      <v-btn
                                        v-if="deliveryType"
                                        tile
                                        small
                                        class="primary-button-color"
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...menu }"
                                      >
                                        {{ deliveryType.title }}
                                        <v-icon right dark>
                                          mdi-chevron-down
                                        </v-icon>
                                      </v-btn>
                                      <!-- <span class="text-caption ml-1">Fee</span> -->
                                    </template>
                                    <span>Click to change delivery method</span>
                                  </v-tooltip>
                                </template>
                                <v-card class="mx-auto" max-width="500" tile>
                                  <template v-for="item in deliveryTypes">
                                    <v-list-item
                                      two-line
                                      :key="item.code"
                                      class="pointer"
                                      @click="setDeliveryType(item)"
                                    >
                                      <v-list-item-content class="pointer">
                                        <v-list-item-title
                                          >{{ item.title }} -
                                          {{ item.currencyCode }}
                                          {{ item.amount }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                          item.description
                                        }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-card>
                              </v-menu>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
                <v-row class="elevation-5 rounded-l">
                  <v-col
                    cols="9"
                    sm="9"
                    class="pt-0 bg-container rounded-l"
                    @click="focus('toAmount')"
                  >
                    <v-text-field
                      ref="toAmount"
                      label="Recipient Gets"
                      v-model="sendMoneyParams.toAmount"
                      placeholder="0.00"
                      @keyup="calculate('toAmount')"
                      persistent-placeholder
                      hint="The amount recipient gets"
                      :persistent-hint="true"
                      clearable
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" class="pa-0 rounded-r">
                    <v-btn
                      tile
                      class="primary-button-color input-append rounded-r"
                    >
                      {{ sendMoneyParams.toCurrencyCode }}
                      <!-- <v-icon right> mdi-chevron-down </v-icon> -->
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="showSendButton">
                  <v-btn
                    style="width: 100%"
                    class="mt-3 send-money-button"
                    elevation="0"
                    x-large
                    @click="emitSend"
                  >
                    Send Money
                  </v-btn>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <!-- -----------------------------------------------
            End Contact Form
        ----------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const basketNamespace = createNamespacedHelpers("basket");
const paymentNamespace = createNamespacedHelpers("payment");
//const cashpowerNamespace = createNamespacedHelpers("cashpower");
const usersNamespace = createNamespacedHelpers("users");
const sendMoneyNamespace = createNamespacedHelpers("sendmoney");
import numeraljs from "numeraljs";

import { createSendMoneyParams, calculate } from "../../lib/send-money";

//import RevolutCheckout from "@revolut/checkout";
export default {
  name: "ConverterItem",
  data() {
    return {
      buttonColor: "#2196F3",
      input1: null,
      input2: null,
      amountToConvert: 0.0,
      charge: 0.99,
      exchangeRate: 67.3,
      name: "",
      email: "",
      isLarge: this.$vuetify.breakpoint.lgAndUp,
      isSmall: this.$vuetify.breakpoint.mdOnly,
      isXSmall: this.$vuetify.breakpoint.smAndDown,
      sendMoneyParams: {
        fromAmount: null,
        fromCurrencyCode: "GBP",
        transferType: "INSTANT-BANK",
        transferTypeAmount: 0.0,
        deliveryType: "COLLECTION",
        deliveryTypeAmount: 0.0,
        toAmount: null,
        toCurrencyCode: "GMD",
       // exchangeRate: 0.0,
        recipient: {
          fullname: null,
          mobileNumber: null,
        },
      },
      dialog: false,
      e6: 1,
      /*       recipientName: "",
      recipientNumber: "",
      senderNumber: "",
      senderEmail: "",
      cardNumber: "", */
      valid: true,
      validAmount: true,

      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => (v && v.length > 0) || "Amount must be greater than 0",
      ],
      transferMethod: null,
      transferMethods: [
        {
          code: "bank-transfer",
          amount: 0.99,
          name: "Bank Transfer",
          description:
            "We will issue you a unique code make a transfer from your bank account",
        },
        {
          code: "credit-debit-card",
          amount: 3.99,
          name: "Credit Or Debit Card",
          description: "Using your credit or debit card to transfer",
        },
      ],
      collectionMethod: null,
      collectionMethods: [
        {
          code: "cash-collection",
          amount: 0.0,
          name: "Cash Collection",
          description: "Recipient collects from any of our agent locations",
        },
        {
          code: "bank-deposit",
          amount: 0.0,
          name: "Bank Deposit",
          description: "Transfer directly to a Gambian bank account",
        },
      ],
    };
  },
  props: {
    showSendButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    //CardPayment: () => import("@/components/payment/card-payment"),
    //SuccessAlert: () => import("@/components/alert/success"),
  },
  computed: {
    ...basketNamespace.mapState({
      number: (state) => state.number,
      // operator: (state) => state.operator,
      bundles: (state) => state.bundles,
      sFromCurrencyCode: (state) => state.fromCurrencyCode,
      toCurrencyCode: (state) => state.toCurrencyCode,
      currencies: (state) => state.currencies,
      intents: (state) => state.intents,
    }),
    ...paymentNamespace.mapState({
      paymentID: (state) => state.paymentID,
      paymentParams: (state) => state.paymentParams,
      paymentIntent: (state) => state.paymentIntent,
    }),
    ...usersNamespace.mapState({
      serverConfig: (state) => state.serverConfig,
    }),
    ...sendMoneyNamespace.mapState({
      lTransferTypes: (state) => state.transferTypes,
      lDeliveryTypes: (state) => state.deliveryTypes,
      exchangeRates: (state) => state.exchangeRates,
      stateParams: (state) => state.params,
      configSet: (state) => state.configSet,
    }),
    isMobile() {
      const mdDown = this.$store.state.breakpoints.mdDown;
      return mdDown.indexOf(this.$mq) > -1;
    },
    isLoadingConfig() {
      return this.$store.state.loadingConfig;
    },
    transferTypes() {
      const currencyCode = this.sendMoneyParams.fromCurrencyCode;
      const transferTypes = this.lTransferTypes
        ? this.lTransferTypes.filter(
            (item) => item.currencyCode === currencyCode
          )
        : [];
      return transferTypes;
    },
    transferType() {
      const transferType = this.sendMoneyParams.transferType;
      const result = this.lTransferTypes
        ? this.lTransferTypes.find((item) => item.code === transferType)
        : null;

      return result ? result : this.lTransferTypes[0];
    },
    deliveryTypes() {
      const currencyCode = this.sendMoneyParams.fromCurrencyCode;
      return this.lDeliveryTypes
        ? this.lDeliveryTypes.filter(
            (item) => item.currencyCode === currencyCode
          )
        : [];
    },
    deliveryType() {
      const deliveryType = this.sendMoneyParams.deliveryType;
      return this.lDeliveryTypes
        ? this.lDeliveryTypes.find((item) => item.code === deliveryType)
        : [];
    },
  },
  methods: {
    ...paymentNamespace.mapActions(["aCreatePaymentIntent"]),
    ...paymentNamespace.mapMutations(["mStartPayment"]),
    ...basketNamespace.mapActions(["aSetDetails"]),
    ...sendMoneyNamespace.mapMutations(["mSetParams"]),
    emitSend() {
      this.validAmount = this.$refs.amountForm.validate();
      if (this.validAmount) {
        const params = { ...this.sendMoneyParams };
        console.log("this.sendMoneyParams", this.sendMoneyParams);
        params.initiator = "send-money-public";
        params.startStep = 2;
        this.$emit("send", params);
      } else {
        //this.$store.commit('mShowAlert', "Fill missing fields ");
      }
    },
    validate() {
      this.$refs.form.validate();
      this.e6++;
    },
    cancelBuy() {
      this.recipientName = "";
      this.recipientNumber = "";
      this.senderNumber = "";
      this.senderEmail = "";
      // console.log("buy cancelled");
      this.dialog = false;
    },

    focus(ref) {
      try {
        this.$refs[ref].$refs.input.focus();
      } catch (error) {
        // error
      }
    },
    async calculate(name) {
      try {
        const payload = {
          calculationBase: name,
          recipient: {},
          sender: {},
          item: {}
        };

        payload.calculationBase = payload.calculationBase === 'fromAmount' || payload.calculationBase === 'input1' ? 'from' : 'to';
        
        const that = this;
        const transferTypes = this.lTransferTypes;
        const transferType = transferTypes.find(
          (item) => item.code === that.sendMoneyParams.transferType
        );
        if (!transferType) {
          that.sendMoneyParams.transferType = transferTypes[0].code;
        }
        that.sendMoneyParams.paymentType = that.sendMoneyParams.transferType;


        payload.item = JSON.parse(JSON.stringify(that.sendMoneyParams));

        console.log("payload", payload);

        const sendMoneyParams = calculate(
          name,
          that.sendMoneyParams,
          that.exchangeRates,
          that.transferTypes,
          that.deliveryTypes
        );

        console.log("sendMoneyParams", sendMoneyParams);
        
        this.sendMoneyParams = { ...sendMoneyParams };
        this.mSetParams(sendMoneyParams);
      } catch (error) {
        console.log("error", error);
      }
    },
    async calculateLocal(name) {
      try {
        const that = this;
        const transferTypes = this.lTransferTypes;
        const transferType = transferTypes.find(
          (item) => item.code === that.sendMoneyParams.transferType
        );
        if (!transferType) {
          that.sendMoneyParams.transferType = transferTypes[0].code;
        }
        const sendMoneyParams = calculate(
          name,
          that.sendMoneyParams,
          that.exchangeRates,
          that.transferTypes,
          that.deliveryTypes
        );

        
        this.sendMoneyParams = { ...sendMoneyParams };
        this.mSetParams(sendMoneyParams);
      } catch (error) {
        console.log("error", error);
      }
    },
    calculateOld(name) {
      const input1 = this.input1 ? this.input1 : 0.0;
      const charge = this.transferMethod.amount;
      let amountToConvert = 0;
      const input2 = this.input2 ? this.input2 : 0.0;
      if (name === "input1") {
        amountToConvert = input1 && input1 > charge ? input1 - charge : 0.0;
        this.input2 = amountToConvert * this.exchangeRate;
        this.input2 = this.input2
          ? numeraljs(this.input2).format("0.00")
          : null;
      } else {
        this.input1 = input2 ? input2 / this.exchangeRate + charge : null;
        this.input1 = this.input1
          ? numeraljs(this.input1).format("0.00")
          : null;
      }

      amountToConvert =
        this.input1 && this.input1 > charge ? this.input1 - charge : 0.0;
      this.amountToConvert = numeraljs(amountToConvert).format("0,0.00");
    },
    setTransferType(transferType) {
      this.sendMoneyParams.transferType = transferType.code;
      this.calculate("fromAmount");
    },
    setDeliveryType(deliveryType) {
      this.sendMoneyParams.deliveryType = deliveryType.code;
      this.calculate("fromAmount");
    },
    setTransferMethod(transferMethod) {
      this.transferMethod = transferMethod;
      this.calculate("input1");
    },
    setCollectionMethod(collectionMethod) {
      this.collectionMethod = collectionMethod;
      this.calculate("input1");
    },
    initiateFirstCalculation() {
      // if config is not set try again
      const that = this;
      const timeout = setTimeout(() => {
        if (that.configSet) {
          that.calculate("fromAmount");
        } else {
          that.initiateFirstCalculation();
        }
        clearTimeout(timeout);
      }, 100);
    },
    initiate() {
      const stateParams = { ...this.stateParams };
      console.log("stateParams", stateParams);
      // stateParams.fromCurrencyCode = 'EUR';
      this.sendMoneyParams = createSendMoneyParams(stateParams);
      this.initiateFirstCalculation();
    },
  },
  mounted() {
    const that = this;
    const timeout = setTimeout(() => {
      that.initiate();
      clearTimeout(timeout);
    }, 500);
  },
};
</script>


<style>
@import "@tenant/components/converter/send-money-converter.scss";

/* .converter .v-input__slot::before {
  border-style: none !important;
}
.converter .bg-container {
  background-color: white;
}
.converter input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.converter input[type="number"] {
  -moz-appearance: textfield;
}
.converter .input-append {
  height: 100% !important;
  width: 100% !important;
  font-size: 22px;
}
.converter .mt-minus {
  margin-top: 0;
}
.converter .pointer {
  cursor: pointer;
}
.converter .timeline-item {
  margin-left: -20px;
}
.label-icon {
  font-weight: bold;
  margin-top: -2px;
}
.converter .currency-value{
  color: white;
} */
</style>
